"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// ================================================================================= IE11
// https://www.npmjs.com/package/url-search-params-polyfill
// npm install url-search-params-polyfill --save
require("url-search-params-polyfill");
// https://www.npmjs.com/package/formdata-polyfill
// npm install formdata-polyfill
require("formdata-polyfill"); // https://github.com/jimmywarting/FormData/issues/55
// npm install --save @types/gtag.js
// [x: string]: () => void;
/* interface Window extends EventTarget, WindowTimers, WindowSessionStorage, WindowLocalStorage, WindowConsole, GlobalEventHandlers, IDBEnvironment, WindowBase64, GlobalFetch, WindowOrWorkerGlobalScope, WindowEventHandlers {
    Coiso: () => void;
}

;( function( window ) {

    function Coiso() {
        console.log('PROTOOOO');
    }

    Coiso.prototype.options = {
    }

    window.Coiso = Coiso;
})( window );

const dlg = new Coiso(); */
// https://www.youtube.com/watch?v=4jb4AYEyhRc
/* function Coiso(cena: String): void {
    this.cena = cena;
    this.botao = "Xuxa";
}

Coiso.prototype.proti = function() {
    this.botao = "Proto";
}

const chi = new Coiso('Pupas');

console.log(chi); */
function validateEmailRegExp($emailAdd) {
    var emailRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegExp.test($emailAdd);
}
// let ja_foi_validado: Boolean = false;
var formulario;
// let agree_check_box: HTMLInputElement;
// let agree_check_box_label: HTMLInputElement;
// let notification: HTMLElement;
// let notification_wrapper: HTMLElement;
// let check_icon_msg_wrapper: HTMLElement;
// let cross_icon_msg_wrapper: HTMLElement;
var enviar_bot;
// let required: NodeList;
// let total_required: Number;
// let total_form_control: Number;
var num_min_chars_q_deve_ter = 4;
// let tudo_bem: number = 0;
//const $gaFormEventPrefix: String = 'Form.:';
document.addEventListener('DOMContentLoaded', function () {
    var dados_inline = document.getElementById('dados-inline');
    ;
    (function (window) {
        // let incr = Number(0);
        function ListaForms(formulario_section, form, bot) {
            this.formulario_section = formulario_section;
            this.form = form;
            this.agree_check_box = this.form.querySelector('.custom-control-input');
            this.agree_check_box_label = this.form.querySelector('.custom-control-label');
            this.notification = formulario_section.querySelector('.notification');
            this.notification_wrapper = this.notification.querySelector('.notification__wrapper');
            this.check_icon_msg_wrapper = this.notification_wrapper.querySelector('.notification__check');
            this.cross_icon_msg_wrapper = this.notification_wrapper.querySelector('.notification__cross');
            this.required = this.form.querySelectorAll('.required');
            this.total_required = this.required.length;
            this.form_control = this.form.querySelectorAll('.form-control'); // inputs
            this.total_form_control = this.form_control.length;
            this.bot = bot;
            this.ja_foi_validado = Boolean(false);
            this.tudo_bem = Number(0);
            //console.log(Array(JSON.parse(String(dados_inline.dataset.mailTitulo))));
            // this.mail_prefixs = JSON.parse(String(dados_inline.dataset.mailPrefix))[incr];
            // this.mail_titulos = JSON.parse(String(dados_inline.dataset.mailTitulo))[incr];
            // this.mail_assuntos = JSON.parse(String(dados_inline.dataset.mailAssunto))[incr];
            // console.log('dados_inline', JSON.parse(String(dados_inline.dataset.mailTitulo))[incr]);
            // incr++;
            this.form.onsubmit = function () {
                return false;
            };
        }
        ListaForms.prototype.clicaProto = function () {
            var este = this;
            // console.log('clica');
            if (validacaoTodos(este) === true) {
                if (document.body.contains(este.agree_check_box) === true && este.agree_check_box.checked !== true) {
                    return;
                }
                envio(este);
            }
            este.check_icon_msg_wrapper.classList.add('d-none');
            este.cross_icon_msg_wrapper.classList.add('d-none');
        };
        window.ListaForms = ListaForms;
    })(window);
    var formulario_wrapper = document.querySelectorAll('.formulario');
    // ================================================================================= IE11
    // https://ultimatecourses.com/blog/ditch-the-array-foreach-call-nodelist-hack
    var forEach = function (array, callback) {
        for (var i = 0; i < array.length; i++) {
            callback.call(i, array[i]);
        }
    };
    forEach(formulario_wrapper, function (item, value) {
        // formulario_wrapper.forEach( function(item: HTMLElement) {
        var formulario_section = item;
        formulario = item.querySelector('form');
        enviar_bot = formulario.querySelector('.formulario-button');
        var lista = new ListaForms(formulario_section, formulario, enviar_bot);
        enviar_bot.addEventListener('click', lista.clicaProto.bind(lista));
        // --------------------------------------------- KEYBOARD INPUT VALIDATION EVENT LISTENERS
        formulario.addEventListener('focus', function (focus_event) {
            var focus_event_target = focus_event.target;
            if (focus_event_target.tagName === 'INPUT' || focus_event_target.tagName === 'TEXTAREA') {
                if (focus_event_target.value === focus_event_target.defaultValue) {
                    focus_event_target.value = '';
                    if (lista.ja_foi_validado === false) {
                        focus_event_target.classList.remove('error');
                    }
                }
            }
        }, true);
        // https://javascript.info/keyboard-events
        // https://keycode.info/
        function telNumericInput(key_event) {
            if ((key_event.key >= '0' && key_event.key <= '9') // nums
                || key_event.key == '+'
                || key_event.key == '('
                || key_event.key == ')'
                || key_event.key == '-'
                || key_event.key == 'ArrowLeft'
                || key_event.key == 'ArrowRight'
                || key_event.key == 'Delete'
                || key_event.key == 'Backspace'
                || key_event.key == 'Control' || key_event.key == 'v'
                || key_event.key == ' '
                || key_event.key == 'Tab') {
                // || key_event.keyCode == 32 // space bar
                // || key_event.keyCode == 9) { // tab
                return true;
            }
            key_event.preventDefault();
        }
        formulario.addEventListener('keydown', function (key_event) {
            var key_event_target = key_event.target;
            if (key_event_target.classList.contains('numeric')) {
                telNumericInput(key_event);
            }
        });
        formulario.addEventListener('keyup', function (key_event) {
            var key_event_target = key_event.target;
            if (key_event_target.tagName === 'INPUT' || key_event_target.tagName === 'TEXTAREA') {
                if (key_event.key !== 'Tab' && key_event.key !== 'Shift') { // se não for a tecla TAB + SHIFT
                    validateInputAndTextarea(key_event_target, lista);
                }
            }
        });
        formulario.addEventListener('blur', function (blur_event) {
            var blur_event_target = blur_event.target;
            if (blur_event_target.tagName === 'INPUT' || blur_event_target.tagName === 'TEXTAREA') {
                if (blur_event_target.value === '') {
                    blur_event_target.value = blur_event_target.defaultValue;
                    if (lista.ja_foi_validado === false) {
                        blur_event_target.classList.remove('error');
                    }
                }
            }
        }, true);
        // --------------------------------------------------------------------- NOTIFICATION EVENT LISTENERS
        function animationendPrefixed() {
            var el = document.createElement('div'); // dummy element
            if (el.style.webkitAnimation)
                return String('webkitAnimationEnd');
            return String('animationend'); // default
        }
        var animationend = animationendPrefixed();
        function animationendCallBack(event) {
            if (window.getComputedStyle(lista.notification).opacity == '0' && window.getComputedStyle(lista.notification).display == 'flex') {
                lista.notification.classList.remove('d-flex');
                lista.notification.classList.remove('fade-in');
                lista.notification.classList.remove('bg-sucesso');
                lista.notification.classList.remove('bg-danger');
                lista.notification.classList.add('d-none');
                // console.log('TERMINOU O FADE-OUT');
            }
        }
        lista.notification.addEventListener(animationend, animationendCallBack, false);
        // ---------------------------------------------
        lista.notification_wrapper.addEventListener('click', function (event) {
            event.preventDefault();
            lista.notification.classList.add('fade-out');
        });
    }); // end forEach
    // --------------------------------------------- INPUT FIELDS VALIDATION FUNCTIONS
    function validateInputAndTextarea(input_and_textarea_el, este) {
        // function validateInputAndTextarea(input_and_textarea_el: HTMLInputElement) {
        if (input_and_textarea_el.type === 'email') { // validação do email
            var mailValid = validateEmailRegExp(input_and_textarea_el.value);
            if (mailValid === false) {
                input_and_textarea_el.classList.add('error');
            }
            else {
                input_and_textarea_el.classList.remove('error');
                este.tudo_bem += 1;
            }
        }
        else { // validação dos restantes input + textarea
            if (input_and_textarea_el.value.length < num_min_chars_q_deve_ter || input_and_textarea_el.value === input_and_textarea_el.defaultValue) {
                input_and_textarea_el.classList.add('error');
            }
            else {
                input_and_textarea_el.classList.remove('error');
                este.tudo_bem += 1;
            }
        }
        // console.log('validateInputAndTextarea', input_and_textarea_el.type, este.tudo_bem);
    }
    // function validateCheckbox(checkbox_el: HTMLInputElement) {
    function validateCheckbox(este) {
        if (document.body.contains(este.agree_check_box) === true && este.agree_check_box.checked === false) {
            este.agree_check_box_label.classList.add('error');
        }
        else if (document.body.contains(este.agree_check_box) === true && este.agree_check_box.checked === true) {
            este.agree_check_box_label.classList.remove('error');
            este.tudo_bem += 1;
        }
    }
    function validacaoTodos(este) {
        este.ja_foi_validado = true;
        este.tudo_bem = 0;
        var i = 0;
        for (i = 0; i < este.total_required; ++i) {
            if (este.required[i].tagName === 'INPUT' || este.required[i].tagName === 'TEXTAREA') { // INPUT + TEXTAREA
                validateInputAndTextarea(este.form_control[i], este);
            }
            else if (document.body.contains(este.agree_check_box) === true && este.required[i].tagName === 'LABEL') { // CHECKBOX
                validateCheckbox(este);
            }
        }
        // console.log('.|.', este.tudo_bem, este.total_required, este.agree_check_box.checked);
        if (document.body.contains(este.agree_check_box) === true) {
            if (este.tudo_bem === este.total_required && este.agree_check_box.checked === true) {
                return true;
            }
        }
        else {
            if (este.tudo_bem === este.total_required) {
                return true;
            }
        }
    } //end validacaoTodos func
    // --------------------------------------------- NOTIFICATION
    function notificacao(type, este) {
        // este.notification.classList.remove('d-none', 'fade-out', 'bg-sucesso', 'bg-danger');
        // classlist caniuse ie11: Does not support multiple parameters for the add() & remove() methods
        este.notification.classList.remove('d-none');
        este.notification.classList.remove('fade-out');
        este.notification.classList.remove('bg-sucesso');
        este.notification.classList.remove('bg-danger');
        este.check_icon_msg_wrapper.classList.remove('d-block');
        este.cross_icon_msg_wrapper.classList.remove('d-block');
        if (type === 'enviado') {
            var check_img_el = este.check_icon_msg_wrapper.querySelector('.notification__icon');
            var check_img_data = check_img_el.dataset.src;
            check_img_el.setAttribute('src', check_img_data);
            este.notification.classList.add('d-flex');
            este.notification.classList.add('fade-in');
            este.notification.classList.add('bg-sucesso');
            este.check_icon_msg_wrapper.classList.remove('d-none');
            este.check_icon_msg_wrapper.classList.add('d-block');
        }
        else if (type === 'erro') {
            var cross_img_el = este.cross_icon_msg_wrapper.querySelector('.notification__icon');
            var cross_img_data = cross_img_el.dataset.src;
            cross_img_el.setAttribute('src', cross_img_data);
            este.notification.classList.add('d-flex');
            este.notification.classList.add('fade-in');
            este.notification.classList.add('bg-erro');
            este.cross_icon_msg_wrapper.classList.remove('d-none');
            este.cross_icon_msg_wrapper.classList.add('d-block');
        }
    }
    // --------------------------------------------- SENDING
    function envio(este) {
        // https://stackoverflow.com/questions/9713058/send-post-data-using-xmlhttprequest
        var xhr = new XMLHttpRequest();
        var $ficheiroPHP = String(dados_inline.dataset.url) + '/email/pombocorreio.php';
        var dados_form = new FormData(este.form);
        // ================================================================================= IE11
        /* new Request(este.form, {
            method: 'post',
            body: dados_form._blob ? dados_form._blob() : dados_form
        }) */
        // este.bot.innerHTML = String(dados_inline.dataset.botEnviando);
        este.bot.innerHTML = String(este.form.dataset.botEnviando);
        // http://www.masteringreactjs.com/2018/04/11/one-neat-typescript-trick-that-saved-the-day/
        var dados_serializados = new URLSearchParams(dados_form);
        // const dados_serializados: URLSearchParams = new URLSearchParams((dados_form).toString());
        xhr.open('POST', $ficheiroPHP, true);
        xhr.onload = function () {
            // console.log('onload - DONE', xhr.readyState, 'status: ', xhr.status, 'responseText: ', xhr.responseText); // readyState will be 4
            if (xhr.readyState === 4 && xhr.status === 200 && xhr.responseText === 'true') { // enviado
                document.removeEventListener('blur', function () { });
                // if (typeof gtag === 'function') { gtag('event', String(este.mail_prefixs), { 'event_category' : String(este.mail_prefixs + dados_inline.dataset.titulo), 'event_label' : String(este.mail_prefixs + dados_inline.dataset.titulo) }); }
                if (typeof gtag === 'function') {
                    gtag('event', String(este.form.dataset.mailPrefix), { 'event_category': String(este.form.dataset.mailPrefix), 'event_label': String(este.form.dataset.mailPrefix) + ':' + String(dados_inline.dataset.lg) });
                }
                notificacao('enviado', este);
                este.form.reset();
            }
            else {
                notificacao('erro', este);
            }
            este.ja_foi_validado = false;
            // este.bot.innerHTML = String(dados_inline.dataset.botEnviar);
            este.bot.innerHTML = String(este.form.dataset.botEnviar);
        };
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        // xhr.send(dados_serializados + '&agreement='+ este.agree_check_box_label.innerHTML + '&lg=' + dados_inline.dataset.lg + '&mailtitulo=' + este.mail_titulos + '&mailsubject=' + este.mail_assuntos + '&formid=' + este.form.getAttribute("id") + '&purl=' + window.location.href);
        if (document.body.contains(este.agree_check_box) === true) {
            xhr.send(dados_serializados + '&agreement=' + este.agree_check_box_label.innerHTML + '&lg=' + dados_inline.dataset.lg + '&mailtitulo=' + este.form.dataset.mailTitulo + '&mailsubject=' + este.form.dataset.mailAssunto + '&formid=' + este.form.getAttribute("id") + '&purl=' + window.location.href);
        }
        else {
            xhr.send(dados_serializados + '&lg=' + dados_inline.dataset.lg + '&mailtitulo=' + este.form.dataset.mailTitulo + '&mailsubject=' + este.form.dataset.mailAssunto + '&formid=' + este.form.getAttribute("id") + '&purl=' + window.location.href);
        }
    }
}); // DOMContentLoaded
